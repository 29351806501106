.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(245, 242, 242);
  .searchTabContainer {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
