.SearchTab {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 494px;
  height: 55px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  border-radius: 14px;
  border: 1px solid #e1e3e6;
  box-shadow: 9px 32px 35px rgba(0, 0, 0, 0.0464653);

  &.open {
    position: relative;
    height: 446px;
    padding-bottom: 20px;

    .container {
      display: flex;
      flex-direction: column;
    }

    .searchInputContainer {
      margin-bottom: 23px;
      &::after {
        display: block;
      }
    }
  }

  .searchInputContainer {
    position: relative;
    height: 51px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 1px;
      background-color: #ececec;
      border-radius: 12px;
    }

    input {
      width: 100%;
      height: 100%;
      padding-left: 3px;
      padding-right: 3px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: #343434;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  .container {
    position: absolute;
    top: 74px;
    left: 20px;
    right: 20px;
    display: none;
    height: 351px;
    overflow: hidden;

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 23px;

      .clearAllButton {
        padding: 0;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.5px;
        color: #232323;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 20px;
      padding: 0;
      margin: 0;
      overflow-y: auto;
      scrollbar-color: rgba(52, 52, 52, 0.51) white;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(52, 52, 52, 0.51);
        border-radius: 4px;
      }

      .option {
        min-height: 22px;
        padding: 0;
        margin: 0;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #343434;
        cursor: pointer;
      }
    }

    .footer {
      position: relative;
      padding-top: 20px;
      display: flex;
      justify-content: flex-end;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #ececec;
        border-radius: 12px;
      }
    }
  }
}
