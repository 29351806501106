.Button {
  height: 38px;
  padding-left: 25px;
  padding-right: 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.5px;
  color: white;
  background-color: #60d09b;
  border-radius: 50px;
  border: none;
  cursor: pointer;
}
