.Switch {
  .label {
    display: flex;
    gap: 10px;
    cursor: pointer;

    button {
      position: relative;
      display: flex;
      align-items: center;
      width: 37px;
      height: 22px;
      font-size: 0;
      background-color: rgba(0, 28, 61, 0.08);
      border: none;
      border-radius: 20px;
      cursor: pointer;
      transition: background-color 0.05s ease-in-out;

      &[aria-checked='true'] {
        background-color: #457df1;
        &::after {
          left: calc(100% - 1.5px - 19.59px);
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: 1.5px;
        width: 19px;
        height: 19px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0px 2.17647px 5.80392px rgba(0, 0, 0, 0.15);
        transition: left 0.05s ease-in-out;
      }
    }

    .labelText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.5px;
      color: #232323;
    }
  }
}
